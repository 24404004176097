import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Theme from '../components/theme'
import { SEO, Layout, SectionHero } from '../components'
import page from '../data/pages/helpnow.yml'

const HelpnowPage = (): React.ReactElement => {
  return (
    <>
      <SEO title={page?.title} />
      <Theme>
        <div className="fixed z-0 w-full h-full top-0 left-0 opacity-10">
          <StaticImage
            src="../images/bubbles.jpg"
            alt="Fische"
            objectFit="cover"
            className="w-full h-full select-none"
            draggable={false}
          />
        </div>
        <Layout>
          <SectionHero
            textSize="medium"
            title="payactive helpnow"
            titleTag="h1"
            subtitle="Online spenden für schnelle Krisenhilfe"
            text="payactive helpnow ist eine einfache Plug & Play Lösung, über die Kund:innen sicher und digital Spenden bezahlen können. Es sind keine Programmierkenntnisse erforderlich. Das Zahlungstool kann direkt über einen Link in die eigene Infrastruktur z.B. von Hilfsorganisationen oder Unternehmen integriert werden."
            actionLinks={[
              {
                label: 'Mehr erfahren',
                url: 'helpnow-kontakt',
              },
            ]}
            image={
              <StaticImage
                src="../images/mobile-helpnow.png"
                alt="payactive App"
              />
            }
          />
        </Layout>
      </Theme>
    </>
  )
}

export default HelpnowPage
